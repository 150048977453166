import React from "react";

const Spacer = ({ my }) => (
    <div className="row">
        <div className="col-md-12">
            <div className="space" data-my={my}></div>
        </div>
    </div>
);

export default Spacer;