import React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import MetaTags from "react-meta-tags";

const OurCompany = () => (
  <Layout>
    <MetaTags>
      <title>Who We Are</title>
      <meta
        name="description"
        content="Since 2010 we have helped our clients navigate complicated stormwater compliance requirements - Meet our team and get to know our history and mission statement"
      />
    </MetaTags>
    <Hero background="/assets/images/services-hero-bg.jpg">
      <h1 className="text-white">Who We Are</h1>
      <p className="text-white" style={{ maxWidth: `760px`, margin: `auto` }}>
        At Incompli, we believe in fostering a culture of collaboration, fun,
        and community both inside and outside the workplace. Whether it's at our
        industry golf tournaments, cheering on the Dodgers at our company game
        night, celebrating the holidays together, or connecting at trade shows,
        our team thrives on building strong relationships. These moments
        highlight the camaraderie and shared values that drive our commitment to
        delivering top-tier SWPPP services across California and Texas.
      </p>
    </Hero>

    {/* "Who We Are" section */}
    <section className="module">
      <div className="container">
        <Spacer my="20px" />
        <div className="row">
          <div className="col-md-4">
            <div style={{ width: "100%", height: "250px", overflow: "hidden" }}>
              <img
                src="/assets/images/sales.jpg"
                alt="Trade show"
                className="img-fluid"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <p style={{ textAlign: "center", marginTop: "10px" }}>
              Steven, Andrew, and Kelly
            </p>
            <Spacer my="20px" />
            <p>
              Our account managers at a recent trade show! We always look
              forward to connecting with industry professionals and sharing our
              expertise in stormwater compliance.
            </p>
          </div>
          <div className="col-md-4">
            <div style={{ width: "100%", height: "250px", overflow: "hidden" }}>
              <img
                src="/assets/images/la-company.jpg"
                alt="Dodger Stadium"
                className="img-fluid"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <Spacer my="20px" />
            <p>
              Team night at Dodger Stadium, enjoying the game from the suite
              with a great view and great company.
            </p>
          </div>
          <div className="col-md-4">
            <div style={{ width: "100%", height: "250px", overflow: "hidden" }}>
              <img
                src="/assets/images/company.jpg"
                alt="Holiday party"
                className="img-fluid"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <Spacer my="20px" />
            <p>
              Celebrating the season in style at the Aliso Viejo Country Club –
              our team enjoying a festive evening of laughter, good food, and
              great company!
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default OurCompany;
