import React from "react";

const Preloader = () => (
    <div className="page-loader">
        <div className="page-loader-inner">
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
        </div>
    </div>
);

Preloader.defaultProps = {
    show: true
};

export default Preloader;