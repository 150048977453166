import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapContainer = () => {
  
  const mapStyles = {        
    height: "500px",
    width: "100%"};
  
  const defaultCenter = {
    lat: 33.593790, lng: -117.723660
  }

  const locations = [
    {
      name: "Incompli",
      location: { 
        lat: 33.593790,
        lng: -117.723660 
      },
    }
  ];
  
  return (
     <LoadScript
       googleMapsApiKey='AIzaSyAkHYChnRrajNxSwKaEdEivPqjbPxvy2rY'>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={15}
          center={defaultCenter}>
          {
          locations.map(item => {
            return (
            <Marker key={item.name} position={item.location}/>
            )
          })
         }
        </GoogleMap>
     </LoadScript>
  )
}

export default MapContainer;