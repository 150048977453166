import React from "react";
import { baseUrl } from "../../config/config.json";

const TeamItem = ({ member }) => (
    <div className="col-lg-4 col-md-6">
        <div className="team-item">
            <div className="team-image"><img src={baseUrl + member.headshot.url} alt=""/>
                <div className="team-wrap">
                    <div className="team-content">
                        <h6 className="team-name">{member.name}</h6>
                        <div className="team-role">{member.title}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default TeamItem;