import React from "react";
import Layout from "../components/Layout";
import ContactForm from "../components/Contact";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import MetaTags from "react-meta-tags";
import Map from "../components/GoogleMaps";
import IconBoxFixed from "../components/IconBoxFixed";

const Contact = () => (
    <Layout whiteHeader>
        <MetaTags>
            <title>Contact Incompli - California / Texas Stormwater Consultants</title>
            <meta name="description" content="We are ready with our professional stormwater management team to help with your SWPPP development and QSP inspections. Get in touch with our team today!"/>
        </MetaTags>
        <link rel="canonical" href="https://www.incompli.com/contact-us" />
        <Hero background="/assets/images/services-hero-bg.jpg">
            <h1 className="text-white">Contact Us</h1>
            <p className="text-white" style={{ maxWidth: `760px`, margin: `auto` }}>
                We are ready with our experienced, professional stormwater management team to help with your SWPPP development and CESSWI or CISEC certified inspections. Get in touch with our team today!
            </p>
        </Hero>
        <section className="module divider-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 m-auto text-center">
                        <h3>Address</h3>
                    </div>
                </div>
                <Spacer my="20px" />
                <div className="row text-center">
                    <IconBoxFixed title="Southern California">
                        <a href = "https://goo.gl/maps/L4vAoq9q7PrZmxdw9">85 Argonaut #210, Aliso Viejo, CA 92656</a><br/>
                        Contact: <a href="tel:+18006773718">(800) 677-3718</a>
                    </IconBoxFixed>
                    <IconBoxFixed title="Texas">
                        <a href = "https://goo.gl/maps/qs2t68EWj4a4zviS7"> 5 Cowboys Way, Suite 300 Frisco, TX 75034 </a><br/>
                        Contact: <a href="tel:+19724751158">(972) 457-1158</a>
                    </IconBoxFixed>
                    <IconBoxFixed title="Northern California">
                        <a href = "https://goo.gl/maps/pyrxrcGHZY1ZJJfPA">11 North Market Street, Suite 300 San Jose, CA 95113</a><br/>
                        Contact: <a href="tel:+18006773718">(800) 677-3718</a>
                    </IconBoxFixed>
                </div>
            </div>
        </section>
        <ContactForm />
        <Map/>
    </Layout>
);

export default Contact;
