import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

class MenuLink extends React.Component {
    constructor(props) {
        super(props);
    }

    isHashLink(href) {
        let regex = new RegExp("^/?#.*");
        return regex.test(href);
    }

    render() {
        return (
            <li>
                {this.isHashLink(this.props.href) ? (
                    <HashLink to={this.props.href}>
                        <span className="menu-item-span">
                            {this.props.children}
                        </span>
                    </HashLink>
                ) : (
                    <Link to={this.props.href}>
                        <span className="menu-item-span">
                            {this.props.children}
                        </span>
                    </Link>
                )}
            </li>
        );
    }
}

export default MenuLink;
