import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

const WaterSampling = () => (
    <Layout>
        <MetaTags>
            <title>Incompli SWPPP Services - Water Sampling</title>
            <meta name="description" content="Incompli SWPPP Services - Water Sampling." />
        </MetaTags>
        <Hero background="/assets/images/services-hero-bg.jpg">
            <h1 className="text-white">SWPPP Services - Water Sampling</h1>
            <p className="text-white" style={{ maxWidth: `760px`, margin: `auto` }}></p>
        </Hero>
        <section id="module" className="module">
            <div className="container">
                <Spacer my="60px" />
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs nav-justified">
                            <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#tab-5-1">
                                    <h6>When is it Expected?</h6></a></li>
                            <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab-5-2">
                                    <h6>Who does it?</h6></a></li>
                            <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab-5-3">
                                    <h6>What happens to it? </h6></a></li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane show active" id="tab-5-1">
                                 <section id="important">
                                    <p>Dependent on many factors, industrial projects and certain construction sites may require water samples to be taken during rain storms in order to ensure water does not contain pollutants and the site is discharging clean water. Our inspection services include water sampling and/or water sampling training for onsite personnel as needed for each of our customer’s sites. Sampling is not required on all projects.</p>
                                </section>
                            </div>
                            <div className="tab-pane" id="tab-5-2">
                                <section id="important">
                                    <p>Incompli inspectors are CESSWI or CISEC certified and have been trained to collect water samples or train your onsite staff to collect water samples as needed to make sure your project is covered during rain events. They always have their sampling equipment on hand - which is pre-calibrated - so they are ready to take on any water sampling concern at a moment’s notice.</p>
                                </section>
                            </div>
                            <div className="tab-pane" id="tab-5-3">
                                <section id="important">
                                    <p>After the sample has been collected, it will be analyzed immediately in the field or sent to a local laboratory for analysis. If pollutants are detected, our inspectors will work with the site operator to remedy the issues and file any required paperwork.</p>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);
export default WaterSampling
