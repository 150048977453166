import React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import Spacer from "../components/Spacer";
import MetaTags from "react-meta-tags";

const StormwaterCompliance = () => (
    <Layout>
        <MetaTags>
            <title>Manage Stormwater Compliance with a SWPPP Consultant</title>
            <meta name="description" content="When looking for a SWPPP consultant, one must ask the question of why do we need them in the first place? Learn more!"/>
            <link rel="canonical" href="https://www.incompli.com/swppp-services" />
    </MetaTags>
        <Hero background="/assets/images/services-hero-bg.jpg">
            <h1 className="text-white">How can a SWPPP consultant help me manage my stormwater compliance?</h1>
            <p className="text-white" style={{ maxWidth: `760px`, margin: `auto` }}>Before any construction company, homebuilder, industrial facility, or municipality can understand how hiring a SWPPP consultant can help them oversee their project, one must ask the question of why do we need them in the first place? Why can’t I just manage this myself or within my existing staff who already know the project?</p>
        </Hero>
        <section className="module">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 m-auto text-center">
                        <h2>Compliance Prevents Costly Fines</h2>
                    </div>
                </div>
                <Spacer my="30px" />
                <div className="row justify-content-center">
                    <div className="col">
                        Taking a step back, it's important to understand the gravity of what’s at stake in terms of compliance with the CA/TX Construction General Permit (CGP). The CA Water Board and Texas Commission in Environmental Quality (TCEQ) enforce CGP compliance for the state and fined over $14 million in violations during the course of the past year in California alone. You are required to have a SWPPP, enforce it, and maintain adequate BMPs outlined in the SWPPP for the entire duration of the project. If at any time your site is found to be without the proper BMPs in place, not only will you face potential costly fines, but also project delays.
                    </div>
                </div>
                <Spacer my="20px" />
                <div className="row">
                    <div className="col-md-6 m-auto text-center">
                        <h3>What Is the Benefit of Having a Certified SWPPP Consultant?</h3>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col">
                        There are many reasons why it is best to hire an independent SWPPP consultant to oversee your stormwater compliance. One key reason for this has to do with the complexity of the regulations and nuances of the CGP and how they relate to each individual project. SWPPP consultants have a multitude of certifications that specialize in stormwater compliance that require ongoing continuing education, hence they are up-to-date on current requirements and permit changes in real-time. The cost of educating and certifying one of your key project staff - and then keeping them up-to-date on a regular basis - takes them away from other responsibilities and utilizes important project resources. Using a SWPPP consultant allows your employees to focus on keeping the project on track while the consultant oversees the stormwater compliance piece.
                    </div>
                </div>
              <Spacer my="20px" />
               <div className="row">
                    <div className="col-md-6 m-auto text-center">
                        <h4>SWPPP Experts</h4>
                    </div>
                </div>
              <div className="row justify-content-center">
                    <div className="col">
                        In addition to leveraging their expertise, SWPPP consultants have access to even further-reaching stormwater compliance subject matter experts (SMEs) as they work hand-in-hand with the QSDs that develop the SWPPPs/SWP3s. When tough, complex issues come up, they can lean on the team of experts standing behind them to ensure they find a solution. SWPPP consultants have access to resources that allow them to dive deep on issues when needed so you can stay focused on your project.
                    </div>
                </div>
              <Spacer my="20px" />
               <div className="row">
                    <div className="col-md-6 m-auto text-center">
                        <h4>Focused Attention to Detail</h4>
                    </div>
                </div>
              <div className="row justify-content-center">
                    <div className="col">
                        An often overlooked benefit of hiring a SWPPP consultant to oversee your stormwater compliance is that they do not have competing priorities and a divided focus when on site. They are able to be completely focused and honed in on ensuring CGP compliance without project distractions and trying to juggle too many balls at one time.
                    </div>
                </div>
            </div>
        </section>
        <Banner id="getInTouch" image="/assets/images/contact-bg.jpg">
            <h3>What can I expect from a SWPPP Consultant?</h3>
            <p className="lead">SWPPP consultants start by meeting with your site contacts to review the SWPPP and ensure your onsite staff understands exactly what BMPs need to be in place. They are also available for onsite staff training that provide an overview of good housekeeping practices. Each time they monitor your site, they check in with the site contacts to provide an update on anything that needs to be addressed. SWPPP consultants take the weight of compliance off of your employees so they stay focused on the project. This allows them to communicate regularly with key site personnel to provide visibility into your site’s compliance and any issues project management needs to be aware of. 

Hiring a SWPPP consultant to oversee your stormwater compliance provides you with the best protection against costly fines and project delays. They will use their expertise, and leverage the expertise of QSDs as needed, to ensure your project stays on schedule and your site personnel are  able to stay focused on the project. 
</p>
            <Link className="btn btn-lg btn-circle btn-brand" to="/contact-us">Contact Us &rarr;</Link>
        </Banner>
    </Layout>
);

export default StormwaterCompliance;
