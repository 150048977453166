import React, { useRef, useEffect } from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import Spacer from "../components/Spacer";
import MetaTags from "react-meta-tags";
import ContactForm from "../components/DroneContact";
import TagManager from "react-gtm-module";

const DroneConstructionSiteMapping = () => {
  const contactFormRef = useRef(null);

  useEffect(() => {
    const dronePageTagManagerArgs = {
      gtmId: "G-CG11Q97SR4",
    };

    TagManager.initialize(dronePageTagManagerArgs);
  }, []);

  const handleContactClick = () => {
    contactFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("form_success") && contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <Layout>
      <MetaTags>
        <title>Drone Mapping Services for Construction Sites</title>
        <meta
          name="description"
          content="Harness the power of drones with Incompli's construction site mapping services. Gain real-time insights, accurate data capture, and enhanced efficiency."
        />
        <link rel="canonical" href="https://www.incompli.com/drone-mapping" />
      </MetaTags>
      <Hero background="/assets/images/_aerial-drone.jpg">
        <h1 className="text-white">
          Drone Construction Site Mapping Services with Incompli
        </h1>
        <p className="text-white" style={{ maxWidth: `760px`, margin: `auto` }}>
          Incompli is diversifying its operations by entering the fields of
          drone construction, aerial services, mapping, and 3D modeling.
        </p>
        <Spacer my="30px" />
        <button
          onClick={handleContactClick}
          className="btn btn-lg btn-circle btn-brand"
        >
          Contact Us
        </button>
      </Hero>
      <Spacer my="30px" />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-auto text-center">
              <h2>Aerial Excellence</h2>
            </div>
          </div>
          <Spacer my="30px" />
          <div className="row justify-content-center">
            <div className="col">
              With FAA certified drone pilots, we are now able to provide Aerial
              and 3D imaging of your site from start to finish. These images are
              able to show small, impactful details of your project, provide a
              high level project overview, and progress over time.
            </div>
          </div>
          <Spacer my="30px" />
          <div
            className="iframe-container"
            style={{
              textAlign: "center",
            }}
          >
            <p>Interactive 3D Map Model</p>{" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              <iframe
                src="https://www.mapsmadeeasy.com/maps/public_3D/05f0cf5391e34c3c87ed1d2268353dee"
                title="Interactive 3D Map Model"
                style={{
                  border: "2px solid #ccc",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "80%",
                  height: "400px",
                  frameBorder: "0",
                }}
                scrolling="no"
              ></iframe>
            </div>
          </div>

          <Spacer my="30px" />
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <h2>Benefits of Drone Mapping Services with Incompli</h2>
            </div>
          </div>
          <Spacer my="60px" />
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs nav-justified">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#tab-1"
                  >
                    <h6>Real-time insights</h6>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#tab-2">
                    <h6>Accurate data capture</h6>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#tab-3">
                    <h6>Enhanced efficiency</h6>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane show active" id="tab-1">
                  With our drone mapping technology, gain immediate insights into the
                  status and progress of your construction projects. Monitor the
                  site in real-time and make informed decisions.
                  <Spacer my="20px" />
                  <li>Weekly</li>
                  <li>Monthly</li>
                  <li>Quarterly</li>
                  <li>Project before and after 3D site images</li>
                </div>
                <div className="tab-pane" id="tab-2">
                  Capture every detail with the high-resolution imaging
                  capabilities of our drones. Ensure that every data point is
                  accurate and reliable.
                  <Spacer my="30px" />
                  {/* New Image Section for _aerial_image_elev.png */}
                  <section
                    className="image-section-elev"
                    style={{
                      backgroundImage: `url('/assets/images/_aerial_image_elev.png')`,
                      backgroundSize: "contain", // Use "cover" if you want the image to fully cover the section
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      height: "400px", // Set the height you want for this section
                      width: "100%",
                      position: "relative",
                    }}
                      aria-label="Drone Mapping service provides aerial view of construction site showing elevation changes"
                  ></section>
                  <Spacer my="30px" />
                </div>
                <div className="tab-pane" id="tab-3">
                  Drone imaging by FAA certified drone pilots can help monitor
                  safe working conditions, weather impact to the project,
                  housekeeping, construction progress, and more.
                </div>
              </div>
              <Spacer my="30px" />
            </div>
          </div>
        </div>
      </section>

      <ContactForm ref={contactFormRef} />
    </Layout>
  );
};

export default DroneConstructionSiteMapping;
