import React from "react";
import { Link } from "react-router-dom";

const MainHero = ({ image }) => (
    <section className="module-cover parallax text-center fullscreen" data-background={image} data-overlay="0.6">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="m-b-20"><strong>Simplifying Stormwater Compliance with Professionals You Can Trust</strong></h1>
                    <p className="hero-text-block">Let us simplify your needs with our team of California and Texas stormwater compliance SWPPP consulting experts.</p>
                    <p><Link className="btn btn-lg btn-circle btn-brand" to="/contact-us">Contact Us</Link><Link className="btn btn-lg btn-circle btn-outline-new-white" to="/swppp-services">Learn More</Link></p>
                </div>
            </div>
        </div>
    </section>
);

MainHero.defaultProps = {
    image: "/assets/images/module-2.jpg"
};

export default MainHero;
