import React from "react";
import Header from "../Header";
import Preloader from "../Preloader";
import Footer from "../Footer";
import ScriptLoader from "../../utils/ScriptLoader";
import tawkTo from "tawkto-react";

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.scriptLoader = new ScriptLoader([
            "/assets/js/custom/jquery.min.js",
            "/assets/js/custom/popper.min.js",
            "/assets/js/bootstrap/bootstrap.min.js",
            "/assets/js/custom/plugins.js",
            "/assets/js/custom/custom.js",
        ]);
        this.tawkToProperty = "5fbc2860920fc91564c9ebb6";
    }

    componentDidMount() {
        this.scriptLoader.load();
        tawkTo(this.tawkToProperty);
    }

    render() {
        const { children, whiteHeader } = this.props;
        return (
            <>
                <Preloader />
                <Header transparent={!whiteHeader} />
                <div className="r">
                    {children}
                    <Footer />
                </div>
            </>
        );
    }
}

export default Layout;
