import React from "react";
import PropTypes from "prop-types";

const FooterBanner = ({ image, overlay, children, className, ...props }) => (
    <section className={"module-cover parallax " + (className ? className : "")} data-background={image} data-overlay={overlay} {...props}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 text-center">
                    {children}
                </div>
            </div>
        </div>
    </section>
);

FooterBanner.propTypes = {
    image: PropTypes.string,
    overlay: PropTypes.string
};

FooterBanner.defaultProps = {
    image: "assets/images/module-1.jpg",
    overlay: "0.5"
};

export default FooterBanner;
