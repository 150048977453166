import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import FooterBanner from "../components/FooterBanner";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

const SWPPPDevelopment = () => (
    <Layout>
        <MetaTags>
            <title>SWPPP Development In California</title>
            <meta name="description" content="Let our experts take you from start to finish in developing SWPPP that will ensure your project remains CGP compliant. Call today for more information!" />
        </MetaTags>
        <Hero background="/assets/images/services-hero-bg.jpg">
            <h1 className="text-white">SWPPP Development</h1>
            <p className="text-white" style={{ maxWidth: `760px`, margin: `auto` }}>We develop Stormwater Pollution Prevention Plans (SWPPPs/SWP3s) for contractors, government agencies, and property owners. Let our experts take you from start to finish in developing a comprehensive SWPPP that will ensure your project remains compliant with all of the Construction General Permit (CGP) requirements of the state. California and Texas CGP requirements are rigorous and the monetary consequences of being out of compliance are steep. Our team of experts simplify the SWPPP development process, helping you get through state requirements in the most cost-effective, time-efficient manner so that you can keep your project moving forward.</p>
        </Hero>
        <section id="module" className="module">
            <div className="container">
                <Spacer my="60px" />
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs nav-justified">
                            <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#tab-5-1">
                                    <h6>We specialize in developing</h6></a></li>
                            <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab-5-2">
                                    <h6>Why is it important to develop a SWPPP?</h6></a></li>
                            <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab-5-3">
                                    <h6>Making Sure Your Project Doesn’t Negatively Impact the Environment</h6></a></li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane show active" id="tab-5-1">
                                <section id="specialize">
                                    <div>
                                        <ul className="arrow-list">
                                          <li>Construction SWPPPs</li>
                                          <li>Industrial Facility SWPPPs</li>
                                          <li>Caltrans SWPPPs</li>
                                          <li>Water Pollution Control Plans (WPCPs)</li>
                                          <li>Water Quality Management Plans (WQMPs)</li>
                                          <li>Dust Control Plans for Air Quality Management Districts (AQMDs)</li>
                                        </ul>
                                    </div>
                                </section>
                            </div>
                            <div className="tab-pane" id="tab-5-2">
                                <section id="important">
                                    <p>Commercial and private construction projects (and any facility that interacts with its environment) are susceptible to creating a significant negative impact on the environment when it rains. As a result, the CGP places complex requirements on these businesses. Companies must have a SWPPP in place to meet these requirements and avoid costly fines. Our highest priority at Incompli is to protect you by developing a quality SWPPP that will help you remain compliant.</p>
                                </section>
                            </div>
                            <div className="tab-pane" id="tab-5-3">
                                <section id="important">
                                    <p>In order for California and Texas residents and wildlife to enjoy nature in its least-contaminated form, it’s crucial to reduce and eliminate the pollutant contribution of your private or commercial construction project. Our team of experts will develop a Stormwater Pollution Prevention Plan (SWPPP) to do exactly that.</p>
                                    <p>When stormwater runs across non-pervious surfaces, it brings with it all the pollution and toxins it finds as it travels to its final destination, whether that’s a storm drain or a place for the earth to soak it up.</p>
                                    <p>The DC Department of Energy and Environment has identified a myriad of ways <a href="https://doee.dc.gov/service/why-stormwater-problem">unregulated storm run-off can negatively impact the environment </a>around it, including but not limited to impaired and destroyed aquatic life,  increased costs of water and wastewater treatment, and recreational use of bodies of water diminished for such activities as boating, fishing, and swimming. You can prevent stormwater pollution coming from your private or commercial construction site by developing and executing on a quality SWPPP that meets all California and Texas regulatory requirements.</p>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="expect" className="module">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-9 m-auto text-center">
                        <h3>What You Can Expect</h3>
                    </div>
                </div>
                <Spacer my="30px" />
                <div className="row justify-content-center">
                    <div className="col">
                    Incompli stormwater compliance experts are timely and responsive. One of our certified QSDs/SWP3  Developers will work closely with you to develop a SWPPP that will be site- and project-specific. SWPPPs are typically around 500 pages and will include maps, general permits, and customized BMPs specific to your site and project. We will submit your SWPPP to the state-run SMARTS/STEERS system for approval, and we’ll accompany you throughout the process to document and account for any changes in your plan. From start to finish, Incompli has your SWPPP development covered!
                    </div>
                </div>
            </div>
        </section>
        <FooterBanner id="resources" image="/assets/images/our-vision.jpg">
            <h2>Resources</h2>
                <div>
                    <a href="https://doee.dc.gov/service/why-stormwater-problem">DC.gov Department of Energy &amp; Environment, &ldquo;Why is stormwater a problem?&rdquo;</a>
                </div>
                <div className="col">
                    <a href="https://www3.epa.gov/npdes/pubs/sw_swppp_guide.pdf">Environmental Protection Agency&rsquo;s &ldquo;Developing Your Stormwater Pollution Prevention Plan&rdquo;</a>
                </div>
        </FooterBanner>
    </Layout>
);
export default SWPPPDevelopment
