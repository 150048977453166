Element.prototype.remove = function() {
    this.parentElement.removeChild(this);
}

class ScriptLoader {
    constructor(scripts) {
        this.scripts = scripts;
        this.count = scripts.length;
        this.loaded = [];
        this.error = [];
        this.pending = [];
    }

    load() {
        for (let i = 0; i < this.count; i++) {
            const src = this.scripts[i];
            this.loadSrc(src);
        }
    }

    loadSrc(src) {
        if (this.loaded.indexOf(src) >= 0) {
            return true;
        }

        this.pending.push(src);
        this.createTag(src)
            .then((src) => {
                this.loaded.push(src);
            }).catch((error) => {
                this.error.push(src);
                console.log(error);
            });
    }

    createTag(src) {
        return new Promise((resolve, reject) => {
            const body = document.getElementsByTagName('body')[0];
            const tag = document.createElement('script');

            const existing = document.querySelectorAll(`script[src='${src}']`);
            for (let i = 0; i < existing.length; i++) {
                existing[i].remove();
            }

            tag.type = 'text/javascript';
            tag.async = false;

            tag.addEventListener('load', () => {
                resolve(src);
            });
            tag.addEventListener('error', () => {
                reject(new Error('The tag could not be loaded.'));
            });

            tag.src = src;
            body.appendChild(tag);

            return tag;
        });
    }
}

export default ScriptLoader;