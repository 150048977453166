import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

const OurCredentials = () => (
    <Layout>
        <MetaTags>
            <title>Incompli Credentials > Qualified SWPPP Practitioners</title>
            <meta name="description" content="View our credentials for SWPPP services. We are qualified QSP & QSD practitioners. Directories, licenses, and certifications." />
        </MetaTags>
        <Hero background="/assets/images/services-hero-bg.jpg">
            <h1 className="text-white">Our Credentials</h1>
            <p className="text-white" style={{ maxWidth: `760px`, margin: `auto` }}>
                When it comes to the Stormwater Pollution Prevention Plan (SWPPP) services you need for your project, you want the most knowledgeable professionals at your side. At Incompli, our SWPPP developers take pride in having obtained a range of licenses, certifications, and memberships. From development to inspection, and from monitoring to reporting, you can rest assured you have access to the highest level of education and experience.
            </p>
        </Hero>
        <section id="module" className="module">
            <div className="container">
                <Spacer my="60px" />
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs nav-justified">
                            <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#tab-5-1">
                                    <h6>SWPPP Licenses</h6></a></li>
                            <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab-5-2">
                                    <h6>SWPPP Credentials and Certifications</h6></a></li>
                            <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab-5-3">
                                    <h6>SWPPP Affiliations and Memberships</h6></a></li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane show active" id="tab-5-1">
                                <section id="tab1IdName">
                                    <a href="https://california.licensesearch.org/business/">California Business License Directory</a>
                                    <p>We have SWPPP business licenses for the following cities throughout Northern California, Southern California and everywhere in between.</p>
                                    <div>
                                        <ul className="arrow-list">
                                            <li>ANAHEIM</li>
                                            <li>SAN LUIS OBISPO</li>
                                            <li>SAN FRANCISCO</li>
                                            <li>VENTURA</li>
                                            <li>FREMONT</li>
                                            <li>SOUTH SAN FRANCISCO</li>
                                            <li>TORRANCE</li>
                                            <li>MOUNTAIN VIEW</li>
                                            <li>CARLSBAD</li>
                                            <li>DOWNEY</li>
                                            <li>PERRIS</li>
                                            <li>FULLERTON</li>
                                            <li>RIVERSIDE</li>
                                            <li>PARAMOUNT</li>
                                            <li>CATHEDRAL CITY</li>
                                            <li>PALMDALE</li>
                                            <li>RANCHO CUCAMONGA</li>
                                            <li>PALM DESERT</li>
                                        </ul>
                                    </div>
                                </section>
                            </div>
                            <div className="tab-pane" id="tab-5-2">
                                <section id="tab2IdName">
                                    <p><a href="http://owp.csus.edu/qsd-lookup.php">Water Programs Verification at Sacramento State</a></p>
                                </section>
                            </div>
                            <div className="tab-pane" id="tab-5-3">
                                <section id="tab3IdName">
                                    <p><a href="https://www.casqa.org/">California Stormwater Quality Association (CASQA)</a></p>
                                    <p><a href="https://www.nahb.org/">National Association of Home Builders (NAHB)</a></p>
                                    <p><a href="https://cbia.org/">California Building Industry Association (BIA)</a></p>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default OurCredentials
