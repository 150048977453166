import React from "react";
import PageItem from "./PageItem";

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: this.getPageCount(),
            current: this.getCurrent()
        };
    }

    getPageCount() {
        const { total, limit } = this.props;
        return Math.ceil( total / limit );
    }

    getCurrent() {
        const { start, limit } = this.props;
        if (start === 0) {
            return 1;
        } else {
            return limit / start;
        }
    }

    render() {
        const { onChange } = this.props;
        const { pages, active } = this.state;
        return (
            <div className="row">
                <div className="col-md-12">
                    <nav>
                        <ul className="pagination justify-content-center">
                            <li className="page-item">
                                <a
                                    className="page-link"
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const prev = active - 1;
                                        if (prev < 0) {
                                            return 0;
                                        } else {
                                            return prev;
                                        }
                                    }}
                                >
                                    <span className="fas fa-angle-left"></span>
                                </a>
                            </li>
                            {(new Array(pages)).map((value, i) => (
                                <PageItem
                                    key={i}
                                    number={i+1}
                                    active={(i+1) === active}
                                    onClick={onChange}
                                />
                            ))}
                            <li className="page-item">
                                <a
                                    className="page-link"
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const next = active + 1;
                                        if (next > pages) {
                                            return pages;
                                        } else {
                                            return next;
                                        }
                                    }}
                                >
                                    <span className="fas fa-angle-right"></span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }
}

export default Pagination;