import React from 'react';
import Layout from "../components/Layout";
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <div className="text-center">
            <img src="https://i.imgur.com/qIufhof.png" />
            <div>
                <h4>This page could not be found</h4>
                <Link to="/">Go Home </Link>
            </div>
        </div >
    )
}

export default PageNotFound