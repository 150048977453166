

import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import IconBox from "../components/IconBox";
import ContactForm from "../components/Contact";

const SWPPPConsultingAndTraining = () => (
    <Layout>
        <MetaTags>
            <title>SWPPP Training in California and Texas - Incompli</title>
            <meta name="description" content="Incompli SWPPP Consulting And Training." />
        </MetaTags>
        <Hero background="/assets/images/services-hero-bg.jpg">
            <h1 className="text-white">SWPPP Consulting And Training</h1>
            <p className="text-white" style={{ maxWidth: `760px`, margin: `auto` }}></p>
        </Hero>
        <section className="module divider-bottom">
            <div className="container">
                <Spacer my="20px" />
                <div className="row">
                    <IconBox title="Stormwater Training ">
                        Stormwater requirements are complex and can be hard to keep up with. Ensuring that key personnel related to each project are trained properly and that training is ongoing to address any changes is critical to the continued compliance of your site.
                    </IconBox>

                    <IconBox title="External Training">
                        We offer private training on a case-by-case basis from our certified Trainer of Record who is able to train inspectors and Qualified SWPPP/SWP3 Developers (QSDs) per state requirements. Please contact us to learn more about our private stormwater training services.
                    </IconBox>

                    <IconBox title="Internal/Customer Training ">
                        Incompli employees start with a baseline of earning the Qualified Stormwater Manager (QSM) certification to ensure foundational knowledge is in place for the stormwater industry. Additionally, all inspectors are CESSWI or CISEC certified, and also have certifications related to Water Pollution Control Plans (WPCPs), OSHA-30, and First Aid. Our SWPPP development team is certified as a QSD, QSP, QISP, SM-QSD, and CPESC as well as a Trainer of Record per state requirements. In addition to that, our employees are extensively trained within SMARTS/STEERS to ensure they are able to navigate any issues that may come up related to filing NOIs, NOTs, Annual Reports, and more.
                        <br />Our customers receive on-site consulting and training on an ongoing basis through weekly monitoring by our inspectors who ensure they understand any deficiencies and how to resolve them each visit. Additionally, our inspectors provide site personnel training on BMPs, good housekeeping practices, and CGP requirements as requested to help reinforce a solid foundation for employees working on site.
                    </IconBox>

                    <IconBox title="Stormwater Consulting">
                    In addition to standard stormwater services, there are many other things that may be required related to permitting, Stormwater Multiple Application and Report Tracking System (SMARTS) filings in California, State of Texas Environmental Electronic Reporting System (STEERS) in Texas, Annual Reports, SWPPP/ modifications, and amendments. Incompli is able to consult on a case-by-case basis to provide support and consulting outside of SWPPP developing and monitoring to ensure all of your stormwater needs are taken care of.
                    </IconBox>
                </div>
            </div>
        </section>
        <ContactForm />
    </Layout>
);
export default SWPPPConsultingAndTraining
