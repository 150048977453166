import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import FooterBanner from "../components/FooterBanner";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import IconBox from "../components/IconBox";

const SWPPPInspection = () => (
    <Layout>
        <MetaTags>
            <title>Construction SWPPP Stormwater Inspection Services</title>
            <meta name="description" content="Our QSDs provide comprehensive inspection services, rain event inspection services for construction projects, and comprehensive inspections for industrial-permitted facilities." />
            <link rel="canonical" href="https://www.incompli.com/swppp-inspection" />   
    </MetaTags>

        <Hero background="/assets/images/services-hero-bg.jpg">
            <h1 className="text-white">Stormwater Pollution Prevention Plan (SWPPP) Inspections</h1>
            <p className="text-white" style={{ maxWidth: `760px`, margin: `auto` }}>In California and Texas, once a SWPPP has been developed, regular inspections must be performed by a <a href="https://www.incompli.com/qualified-swppp-practitioner">Qualified SWPPP Practitioner (QSP) </a>and a Qualified SWPPP Developer (QSD) to maintain compliance. SWPPP inspections are the backbone of the compliance toolkit. Incompli provides inspection services according to state and local regulations following the guidance of the site-specific SWPPP. We provide comprehensive inspection services, rain event inspection services for construction projects as needed, and comprehensive inspections and support for industrial-permitted facilities.</p>
        </Hero>

        <section className="module divider-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 m-auto text-center">
                        <h1>Stormwater Inspection Services</h1>
                    </div>
                </div>
                <Spacer my="20px" />
                <div className="row">
                    <IconBox title="How to Initiate an Inspection">
                        <p>The contractor, property owner, or facility manager is typically responsible for initiating SWPPP inspections by a CESSWI or CISEC certified inspector (QSP or SWP3 Inspector) and a Qualified SWPPP Developer (QSD). If you do not have an in-house inspector and QSD on staff, you must contract with a company like Incompli for inspection services. Once Incompli has been hired, you can rest assured knowing that we are taking care of all of the state and local inspection requirements.</p>
                        <p>The frequency of inspections are determined by many factors including location, risk of pollution, and length of the project. In general, most sites will require a weekly/bi-weekly inspection.</p>
                    </IconBox>

                    <IconBox title="Why it’s Important to have Scheduled Stormwater Inspections">
                        <p>The California State Water Board and Texas Commission on Environmental Quality (TCEQ) audit construction sites regularly. These audits are unannounced. Your site must be following the BMPs outlined in your SWPPP at the time of your unannounced audit or the state will issue a fine. These fines can be significant and will shut down your project until you produce proof of compliance.</p>
                        <p>Inspections provide routine ongoing accountability for SWPPP requirements. Issues will be identified so that they can be addressed in a timely manner. Additionally, the inspections can help to identify issues with the project SWPPP so that amendments can be made if needed.</p>
                        <p>Our CESSWI and CISEC certified inspectors and QSDs are thorough and provide comprehensive guidance to ensure you remain compliant and avoid any fines or project delays.</p>
                    </IconBox>

                    <IconBox title="What to Expect from a SWPPP Inspection?">
                        <p>Our inspectors and QSDs always start the inspection process by checking in with the project superintendent. They will also look at the project SWPPP to get an understanding of the site-specific requirements.</p>
                        <p>As the inspection moves to the field, BMPs will be evaluated and issues will be documented so that they can be remedied in a timely manner. All stormwater inspection details will be documented within our software system, which allows for complete transparency of the inspection process. Once completed, the report will be delivered digitally to the superintendent for action. Our comprehensive software system provides secure archival of all inspections and documentation per regulatory requirements.</p>
                        <p>Our inspectors are responsible for suggesting updates to the site-specific SWPPP if needed. We will work with the project QSD/SWP3 developer to recommend SWPPP adjustments throughout the life of the project.</p>
                        <p>Regulations require that all projects submit annual reports with detailed compliance information related to the project. Our QSPs/SWP3 inspectors will prepare the report on an annual basis and submit it to the governing agency. For example, in California, we submit annual reports to the state-run SMARTs system, while in Texas they are submitted to STEERS.</p>
                    </IconBox>

                    <IconBox title="Types of Stormwater Inspections">
                        <p>Construction projects typically require weekly inspections by QSPs and periodic inspections throughout the year by a QSD to ensure the project maintains compliance with federal, state, and local requirements. Additional inspections and water sampling may be required when rain is present.</p>
                        <p>Industrial facilities typically require a monthly inspection to ensure the facility is maintaining compliance. Water samples will be required when rain is present and the facility is discharging stormwater.</p>
                        <p>Additional inspection requirements may be present but are dependent on several factors including site or project characteristics and the location of the project or site.</p>
                    </IconBox>
                </div>
            </div>
        </section>
        <FooterBanner id="resources" image="/assets/images/blog-hero-bg.jpg">
            <h2>Resources</h2>
                <div>
                    <a href="https://www.newpig.com/stormwater-inspections-what-you-need-to-know/c/8048?show=All">Stormwater Inspections: What You Need to Know</a>
                </div>
        </FooterBanner>
    </Layout>
);
export default SWPPPInspection
