import React, { useState } from "react";
import PropTypes from "prop-types";

const IconBox = ({ icon, title, children }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <div className="col-md-6">
            <div className="icon-box">
                <div className="icon-box-icon">
                    <span className={icon}></span>
                </div>
                <div className="icon-box-title">
                    <h6>{title}</h6>
                </div>
                <div className={`icon-box-content${isExpanded ? " expand" : ""}`}>
                    <p>{children}</p>
                </div>
                <a className="text-brand" href="/" onClick={(e) => {e.preventDefault();setIsExpanded(!isExpanded)}}>
                    {isExpanded ? "Read Less" : "Read More"}
                </a>
            </div>
        </div>
    );
}

IconBox.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired
}

export default IconBox;