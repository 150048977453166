import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Testimonials from "../components/Testimonials";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

const OurClients = () => (
    <Layout>
        <MetaTags>
            <title>Incompli - Our Clients & Testimonials </title>
            <meta name="description" content="Hear from our past clients. Incompli" />
        </MetaTags>
        <Hero background="/assets/images/services-hero-bg.jpg">
            <h1 className="text-white">Our Clients</h1>
            <p className="text-white" style={{ maxWidth: `760px`, margin: `auto` }}></p>
        </Hero>
        <section id="additional-content" className="module">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-9 m-auto text-center">
                        <h3>Who We Serve:</h3>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col">
                        Incompli proudly provides services for construction professionals who are required to maintain stormwater compliance within the states of California and Texas. Our customers include property owners, developers, construction firms, and manufacturing 
                        companies to name a few, all of who rely on our Stormwater Pollution Prevention Plan (SWPPP/SWP3) expertise and trusted guidance to support their project’s stormwater compliance to successful completion. Construction companies are required to keep 
                        their sites in compliance with the Construction General Permit (CGP), yet they have many other tasks to oversee to keep their project on track. Permit requirements can be complicated and time-consuming to oversee. We specialize in providing the expertise 
                        and oversight necessary to keep projects in compliance with SWPPP requirements so our customers can have peace of mind and focus on other demands of their project. Our CESSWI or CISEC certified inspectors and QSDs can meet the needs of all of our customers wherever 
                        they are in their project timeline; from developing a SWPPP and providing QSP services/monitoring, to filing a Notice of Termination (NOT) or their Annual Report in SMARTS/STEERS. Our team of certified QSPs, QISPs, and QSDs will leverage their knowledge and 
                        experience to ensure successful and compliant project completion.
                    </div>
                </div>
            </div>
        </section>
        <section id="additional-content" className="module">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-9 m-auto text-center">
                        <h3>What We Do:</h3>
                    </div>
                </div>
                <Spacer my="30px" />
                <div className="row justify-content-center">
                    <div className="col">
                        Construction companies, property owners, manufacturing companies and developers face the daunting task of keeping up with complicated requirements from the general permit to ensure their sites are compliant and can face heavy fines for non-compliance. 
                        Our <a href="https://www.incompli.com/qualified-swppp-developer">Qualified SWPPP Developers (QSDs)</a> will create a Stormwater Pollution Prevention Plan (SWPPP) that will outline all the specifications that need to be addressed and will provide steps to be taken to ensure compliance. Our CESSWI or CISEC certified team 
                        of inspectors and QSDs will then provide oversight to ensure those Best Management Practices (BMPs) are in place and in accordance with the general permit. Additionally, something that sets our team apart from the rest is the cutting edge software we use to generate 
                        inspection reports. This software includes photos of any deficiencies and outlines suggested corrective actions in an electronic format, which can then easily be emailed to superintendents, project managers, or any site contacts the customer would like. Once 
                        the project is complete, our team of experts will make the close-out process seamless for our customers by doing a final walk-through and inspection, documenting the project completion, filing the NOT, and completing the Annual Report in SMARTS/STEERS.
                    </div>
                </div>
            </div>
        </section>
        <section id="client-content">
              <div className="row">
                  <div className="col-lg-6 col-md-9 m-auto text-center">
                      <h3>Quotes from Happy Clients</h3>
                  </div>
              </div>
              <Spacer my="30px" />
              <Testimonials />
        </section>
    </Layout>
);

export default OurClients
