import React from "react";
import Spacer from "../Spacer";
import { Link } from "react-router-dom";
import { baseUrl } from "../../config/config.json";
import excerpts from "excerpts";
import RecentPostsContext from "../../utils/RecentPostsContext";

class Posts extends React.Component {
    static contextType = RecentPostsContext;
    render() {
        const posts = this.context;
        return (
            <section className="module">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 m-auto text-center">
                            <h1>Latest articles.</h1>
                            <p className="lead">As one of the leading environmental consulting firms in California, Incompli has plenty of news to keep you up to date.</p>
                        </div>
                    </div>
                    <Spacer my="60px" />
                    <div className="row row-post-masonry">
                        {posts.map((post, i) => (
                            <div key={i} className="col-md-4 post-item">
                                <article className="post">
                                    <div className="post-preview">
                                        <Link to={`/blog/${post.id}`}>
                                            <img src={baseUrl + post.image.formats.medium.url} alt=""/>
                                        </Link>
                                    </div>
                                    <div className="post-wrapper">
                                        <div className="post-header">
                                            <h2 className="post-title">
                                                <Link to={`/blog/${post.id}`}>
                                                    {post.title}
                                                </Link>
                                            </h2>
                                        </div>
                                        <div className="post-content">
                                            <p>{excerpts(post.body, { words: 20, append: '...' })}</p>
                                            <p><Link to={`/blog/${post.id}`}>Read more</Link></p>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}

export default Posts;