import React, { useState } from "react";
import Spacer from "../Spacer";
import { useLocation } from "react-router-dom";

const Contact = () => {
    const [status, setStatus] = useState({
        sent: false,
        success: false,
        error: false
    });

    const queryParams = new URLSearchParams(useLocation().search);

    if (!status.sent && queryParams.has("form_success")) {
        const formSuccess = queryParams.get("form_success");
        setStatus({
            sent: true,
            success: formSuccess === "true",
            error: formSuccess === "false"
        });
    }

    if (status.sent && status.success) {
        if (typeof window != "undefined" && typeof window.gtag != "undefined") {
            window.gtag('event', 'conversion', {'send_to': 'AW-1008298993/IIGqCOTl_5ICEPHX5eAD'});
        }
    }

    return (
        <section className="module divider-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-9 m-auto text-center">
                        <h3>Let's talk.</h3>
                        <p className="lead">Please submit your inquiry by using the form below or by emailing <a href="mailto:info@incompli.com">info@incompli.com</a>.</p>
                    </div>
                    {(status.sent && status.error) ? (
                        <div className="mx-auto alert alert-danger mt-3">
                            The message could not be sent. Please try filling out the form again. If it still does not work, please contact <a href="mailto:info@incompli.com">info@incompli.com</a>.
                        </div>
                    ) : (status.sent && status.success) && (
                        <div className="mx-auto alert alert-success mt-3">
                            Your message was successfully sent, we will get back to you as soon as possible.
                        </div>
                    )}
                </div>
                <Spacer my="60px" />
                <div className="row">
                    <div className="col-md-12">
                        <form id="contact-form" action="https://pd.incompli.com/l/413562/2020-12-07/shhzn6" method="post">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input className="form-control" type="text" name="First Name" placeholder="First Name" required="" />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input className="form-control" type="text" name="Last Name" placeholder="Last Name" required="" />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input className="form-control" type="email" name="E-mail" placeholder="E-mail" required="" />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input className="form-control" type="tel" name="Phone" placeholder="Phone" required="" />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input className="form-control" type="text" name="Company" placeholder="Company" required="" />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input className="form-control" type="text" name="Job Title" placeholder="Job Title" required="" />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea className="form-control" name="Message" placeholder="Message" rows="12" required=""></textarea>
                                    </div>
                                </div>
                                {/* <div className="col-md-12">
                                    <div className="form-group">
                                        <ReCAPTCHA sitekey={recaptcha.siteKey} />
                                    </div>
                                </div> */}
                                <div className="col-md-12">
                                    <p></p>
                                    <div className="text-center">
                                        <button className="btn btn-circle btn-brand" type="submit">{status.loading ? "Loading..." : "Send Message"}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
