import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import RecentPostsContext from "../../utils/RecentPostsContext";

class Footer extends React.Component {
    static contextType = RecentPostsContext;
    render() {
        const posts = this.context;
        return (
            <footer className="footer">
                <div className="footer-widgets">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <aside className="widget widget-text">
                                    <div className="widget-title">
                                        <h6>About Us</h6>
                                    </div>
                                    <div className="textwidget">
                                        <p>Our mission at Incompli is to simplify stormwater compliance by leveraging technology and the expertise of our seasoned SWPPP developers and QSP certified inspectors. We develop a wide-array of plans for different projects, provide QSP services, and offer customized training and consultation services around stormwater compliance in CA and TX.</p>
                                    </div>
                                </aside>
                            </div>
                            {/* <div className="col-md-3">
                                <aside className="widget widget-recent-entries">
                                    <div className="widget-title">
                                        <h6>Recent Posts</h6>
                                    </div>
                                    <ul>
                                        {posts.map((post, i) => (
                                            <li key={i}>
                                                <Link to={`/blog/${post.id}`}>
                                                    {post.title}
                                                </Link>
                                                <span className="post-date">
                                                    {(new Date(post.created_at)).toLocaleDateString('en-US')}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </aside>
                            </div> */}
                            <div className="col-md-4">
                                <aside className="widget widget-text">
                                    <div className="widget-title">
                                        <h6>Locations</h6>
                                    </div>
                                    <div className="textwidget">
                                        <p><a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/cY7M917zWhNHQ35U9">
                                            85 Argonaut <br/>
                                            Suite 210 <br/>
                                            Aliso Viejo, CA 92656 <br/>
                                            </a>
                                            Phone: <a href="tel:+18006773718" onclick=" gtag('event', 'click', {
                                                                          'event_category': 'outbound',
                                                                            'event_label': 'tel:+18006773718',
                                                                            'transport_type': 'beacon',
                                                                            'event_callback': function(){document.location = 'tel:+18006773718';}
                                                                             }); return false;">800-677-3718</a>
                                        </p>
                                        <p><a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/than4uwjg98vFHYB6">
                                            111 North Market Street <br/>
                                            Suite 300 <br/>
                                            San Jose, CA 95113 <br/>
                                        </a>
                                            Phone: <a href="tel:+18006773718" onclick=" gtag('event', 'click', {
                                                                          'event_category': 'outbound',
                                                                            'event_label': 'tel:+18006773718',
                                                                            'transport_type': 'beacon',
                                                                            'event_callback': function(){document.location = 'tel:+18006773718';}
                                                                             }); return false;">800-677-3718</a>
                                        </p>
                                        <p><a target="_blank" rel="noopener noreferrer" href = "https://goo.gl/maps/qs2t68EWj4a4zviS7">
                                            5 Cowboys Way <br/>
                                            Suite 300 <br/>
                                            Frisco, TX 75034<br/>
                                            </a>
                                            Phone: <a href="tel:+19724751158" onclick=" gtag('event', 'click', {
                                                                          'event_category': 'outbound',
                                                                            'event_label': 'tel:+19724751158',
                                                                            'transport_type': 'beacon',
                                                                            'event_callback': function(){document.location = 'tel:+19724751158';}
                                                                             }); return false;">972-457-1158</a>
                                        </p>
                                        <p>
                                            E-mail: <a href="mailto:info@incompli.com" onclick=" gtag('event', 'click', {
                                                                          'event_category': 'outbound',
                                                                            'event_label': 'mailto:info@incompli.com',
                                                                            'transport_type': 'beacon',
                                                                            'event_callback': function(){document.location = 'mailto:info@incompli.com';}
                                                                             }); return false;">info@incompli.com</a>
                                        </p>
                                        <ul className="social-icons">
                                            <li><a href="https://twitter.com/incompli"><i className="fab fa-twitter"></i></a></li>
                                            {/* <li><a href="https://instagram.com"><i className="fab fa-instagram"></i></a></li> */}
                                            <li><a href="https://www.facebook.com/Incompli-100529972074569"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/incompli/"><i className="fab fa-linkedin"></i></a></li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-md-3">
                                <aside className="widget widget-text">
                                    <div className="widget-title">
                                        <h6>Pages</h6>
                                    </div>
                                    <ul className="list-group">
                                        <li className="list-item"><Link to="/">Home</Link></li>
                                        <li className="list-item"><Link to="/swppp-services">What We Do</Link></li>
                                        <li className="list-item"><HashLink to="/#whoWeServe">Who We Serve</HashLink></li>
                                        {/* <li className="list-item"><Link to="/blog">Blog</Link></li> */}
                                        <li className="list-item"><Link to="/contact-us">Contact Us</Link></li>
                                        <li className="list-item"><a href="https://www.incompli.com/Incompli-PrivacyPolicy.pdf">Privacy Policy</a></li>
                                    </ul>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright">
                                    <p>&copy; {(new Date()).getFullYear()} Incompli, All Rights Reserved. Created by <a href="https://dylate.net">Dylate</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
