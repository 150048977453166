import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import IconBox from "../components/IconBox";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

const SWPPPMonitoringAndReporting = () => (
    <Layout>
        <MetaTags>
            <title>SWPPP Monitoring and Reporting</title>
            <meta name="description" content="Incompli SWPPP Monitoring and Reporting" />
        </MetaTags>
        <Hero background="/assets/images/services-hero-bg.jpg">
            <h1 className="text-white">SWPPP Monitoring and Reporting</h1>
        </Hero>
        <section id="additional-content" className="module">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 m-auto text-center">
                        <h3>SWPPP monitoring and reporting for stormwater compliance</h3>
                    </div>
                </div>
                <Spacer my="30px" />
                <div className="row justify-content-center">
                    <div className="col">
                        When it comes to your Stormwater Pollution Prevention Plan (SWPPP), developing the plan is the first step. Once a SWPPP has been developed, it must be submitted to the state for approval. Once approved, your team must now execute on the specific Best Management Practices (BMPs) outlined in your SWPPP.
                        Proper monitoring and reporting on the execution of your BMPs is key to the success meeting state regulations. To oversee proper execution depends on regular inspections, reporting of those inspections, and monitoring your account to ensure all the details and next steps are in order. You can’t afford to wait for an unannounced audit to bring you news of any gaps in your SWPPP. It is imperative to continuously monitor your progress to lessen your project’s negative impact on the environment, and lessen your chances of encountering hefty fines and slow downs from the state.
                        <a href="https://www3.epa.gov/npdes/pubs/msgp_monitoring_guide.pdf">There is a breadth of sampling events you can expect during your project that will ensure continued compliance with local and federal environmental regulations.</a>
                    </div>
                </div>
                <Spacer my="60px" />
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs nav-justified">
                            <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#tab-5-1">
                                    <h6>PREPARATION</h6></a></li>
                            <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab-5-2">
                                    <h6>EXECUTION</h6></a></li>
                            <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab-5-3">
                                    <h6>EVALUATION</h6></a></li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane show active" id="tab-5-1">
                                <section id="tab1IdName">
                                    <p>In the Preparation phase, we will work with you to make several determinations. Specifically, about where samples will be collected, what types of upcoming rain events qualify for sample collection, who on your team we’ll collaborate with to execute the sampling and whether they have the proper <a href="https://www.incompli.com/swppp-consulting-and-training">training and knowledge </a> to assist, which laboratory you will designate for sample analysis, whether your sampling supplies kit is complete and ready, and who will be along to ensure this entire Preparation phase is well documented in your SWPPP.</p>
                                </section>
                            </div>
                            <div className="tab-pane" id="tab-5-2">
                                <section id="tab2IdName">
                                    <p>During Execution, samples will be collected from pre-determined stormwater runoff locations at your site, quality assurance will be evaluated, and we will work together to ensure your samples are expediently delivered to your chosen laboratory. Depending on the expected parameters, a sample can be useful up to 6 months after collection, or may need to be transported and analyzed in as few as seven days to preserve the levels of the sought after benchmarks. It will also be necessary here, as a part of your SWPPP,  to conduct and document a visual evaluation of the collection sites for contaminants, and analyze for such qualities as odor, color, and presence of floating, settled, or suspended solids.</p>
                                </section>
                            </div>
                            <div className="tab-pane" id="tab-5-3">
                                <section id="tab3IdName">
                                    <p>The Evaluation phase is an opportunity to obtain feedback based on the documented findings from the Execution phase spent monitoring how sustainable your SWPPP is throughout your project. We’ll be looking along with you for visual pollutants, benchmarked pollutant levels, and considering what changes or supplementary measures should be implemented to improve results for future. Annual and periodic reporting should indicate improvement over time, and demonstrate the impact of SWPPP changes introduced throughout the ongoing evaluation period that resulted in better outcomes. It may be necessary to take immediate corrective actions if benchmark results for levels of certain contaminants are exceeded, in which case we will assist with documentation of implemented adjustments to protocol as well as required reporting and implementation of changes in your Stormwater Pollution Prevention Plan.</p>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="module divider-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 m-auto text-center">
                        <h1>REPORTING TYPES</h1>
                    </div>
                </div>
                <Spacer my="20px" />
                <div className="row">
                    <IconBox title="Inspection reports">
                    Our QSPs will document in detail their inspection findings and submit the report to the California state SMARTS system. Depending upon the type of SWPPP you have developed, these inspections can take place daily, weekly and/or monthly.
                    </IconBox>

                    <IconBox title="Annual reports">
                    Incompli will help you and your team submit the correct documentation for state-required annual reporting. We’ll use the data we’ve collected in our ongoing stormwater inspections throughout the year to ensure you’re prepared to come out on the other side of the report in the know and successful regarding what works and what needs to be reevaluated at your site.
                    </IconBox>

                    <IconBox title="Discharge sampling services and laboratory analysis">
                    As part of your Stormwater Pollution Prevention Plan, it will be necessary to also inspect and assess the stormwater runoff itself through random sampling. This step ensures continued awareness throughout the duration of your project regarding any possible changes in the surrounding environment and how it may be affected by new pollutants. It also determines how effective your SWPPP is and where adjustments need to be made, if any, before these novel pollutants in the stormwater runoff significantly impact the area.
                    </IconBox>

                    <IconBox title="Rain Event Action Plan (REAP) monitoring">
                    In the event of adverse weather conditions, additional permitting and inspections may be necessary depending on the requirements of your locality. With a Rain Event Action Plan (REAP), our team will ensure you’re prepared before, during, and after a rainstorm, so you are in compliance against any developing environmental conditions occurring in the course of completing your project.
                    </IconBox>
                </div>
            </div>
        </section>
        <Banner id="resources" image="/assets/images/our-vision.jpg">
            <h2>Resources</h2>
            <div>
                <a href="https://www3.epa.gov/npdes/pubs/msgp_monitoring_guide.pdf">EPA Industrial Stormwater Sampling and Monitoring Guide</a>
            </div>
        </Banner>
    </Layout>
);

export default SWPPPMonitoringAndReporting
