import React from "react";
import PropTypes from "prop-types";

const Hero = ({ background, children }) => (
    <section className="module-cover text-center" data-background={background} data-overlay="0.5">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    {children}
                </div>
            </div>
        </div>
    </section>
);

Hero.propTypes = {
    background: PropTypes.string
};

Hero.defaultProps = {
    background: "/assets/images/module-3.jpg"
};

export default Hero;