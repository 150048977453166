import React from "react";
import { Link } from "react-router-dom";

const NavigationMenu = (props) => (
    <header
        className={`header${props.transparent ? " header-transparent" : ""}`}
    >
        <div className="container-fluid">
            <div className="inner-header">
                <Link className="inner-brand" to="/">
                    <img
                        className="light-logo"
                        src="/assets/images/logo2-lite.png"
                        alt=""
                        style={{ height: 35, width: "auto" }}
                    />
                    <img
                        className="dark-logo"
                        src="/assets/images/logo2.png"
                        alt=""
                        style={{ height: 35, width: "auto" }}
                    />
                </Link>
            </div>
            <div className="inner-navigation collapse">
                <div className="inner-nav">
                    <ul>{props.children}</ul>
                </div>
            </div>
            <div className="extra-nav">
                <ul>
                    <li className="nav-toggle">
                        <a
                            href="/"
                            data-toggle="collapse"
                            data-target=".inner-navigation"
                        >
                            <span className="menu-item-span">
                                <i className="ti-menu"></i>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </header>
);

export default NavigationMenu;
