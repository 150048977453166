import React from "react";
import Spacer from "../Spacer";
import { Link } from "react-router-dom";
import TeamItem from "../TeamItem";
import { baseUrl } from "../../config/config.json";

class Team extends React.Component {
    state = {
        members: []
    };

    componentDidMount() {
        const { preview } = this.props;
        let url = baseUrl + `/team-members${preview ? '?_limit=6' : ''}`;
        fetch(url)
            .then((res) => res.json())
            .then((members) => this.setState({
                members
            }))
            .catch((error) => console.log(error));
    }

    render() {
        const { preview } = this.props;
        const { members } = this.state;
        return (
            <section className="module">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 m-auto text-center">
                            <h1>Meet the team.</h1>
                            {preview ? (
                                <p className="lead">See some of our staff below or visit the <Link to="/about">about us</Link> page to see a full list of our team members.</p>
                            ) : (
                                <p className="lead">Meet our staff members who work hard everyday to serve our clients.</p>
                            )}
                        </div>
                    </div>
                    <Spacer my="60px" />
                    <div className="row">
                        {members.map((member, i) => (
                            <TeamItem key={i} member={member} />
                        ))}
                    </div>
                    {preview && (
                        <>
                            <Spacer my="30px" />
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="text-center"><Link className="btn btn-circle btn-outline-brand" to="/about">About Us</Link></p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </section>
        );
    }
}

Team.defaultProps = {
    preview: true
};

export default Team;