import React from "react";
import Layout from "../components/Layout";
import PostPreview from "../components/PostPreview";
import Pagination from "../components/Pagination";
import { baseUrl } from "../config/config.json";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

class Blog extends React.Component {
  state = {
    isLoaded: false,
    start: 0,
    limit: 5,
    total: null,
    posts: [],
    staticBlogs: [
      {
        title: "The Role of QSDs and QSPs in Construction SWPPPs",
        link: "/ConstructionSWPPPBlog",
        description:
          "Learn why SWPPP developers and QSD inspectors are crucial to your success.",
      },
      {
        title: "Understanding Stormwater Permitting Programs",
        link: "/StormwaterPermittingProgramsBlog",
        description:
          "Exploring the intricacies of stormwater permitting programs.",
      },
      {
        title: "Erosion & Sediment Control/Stormwater (SWPPP) Inspections - Incompli",
        link: "/SWPPP-Inspections-Blog",
        description: "A comprehensive guide to erosion control, sediment management, and SWPPP compliance in construction.",
      },
    ],
  };

  componentDidMount() {
    const { start, limit } = this.state;
    fetch(`${baseUrl}/articles/count`)
      .then((res) => res.text())
      .then((text) => {
        this.setState({
          isLoaded: false,
          total: parseInt(text),
        });
        return fetch(`${baseUrl}/articles?_start=${start}&_limit=${limit}`);
      })
      .then((res) => res.json())
      .then((posts) =>
        this.setState({
          isLoaded: true,
          posts,
        })
      )
      .catch((error) =>
        this.setState({
          isLoaded: true,
          error: error.message,
        })
      );
  }

  changePage(page) {
    const { limit } = this.state;
    const start = (page - 1) * limit;
    this.setState({ start });
  }

  render() {
    const { posts, total, start, limit, staticBlogs } = this.state;
    return (
      <Layout>
        <MetaTags>
          <title>Incompli &rArr; Environmental Compliance Blogs</title>
          <meta
            name="description"
            content="Articles about Environmental Compliance and SWPPP Consulting and all you need to do have proper compliance."
          />
        </MetaTags>
        <section
          className="module parallax text-center"
          data-background="/assets/images/blog-hero-bg.jpg"
          data-overlay="0.4"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="text-white">Blog</h1>
                <p className="text-white hero-text-block">
                  Take a look at some of our latest articles below!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                {/* Render Static Blogs */}
                {staticBlogs.map((blog, i) => (
                  <div className="post-preview" key={i}>
                    <h3 className="post-title">
                      <Link to={blog.link}>{blog.title}</Link>
                    </h3>
                    <p className="post-meta">{blog.description}</p>
                  </div>
                ))}
                {/* Render fetched posts */}
                {posts.map((post, i) => (
                  <PostPreview key={i} post={post} />
                ))}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Blog;
