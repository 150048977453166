import React from "react";
import Spacer from "../Spacer";

const Testimonials = () => (
    <section className="module-cover parallax" data-background="/assets/images/testimonials-bg.jpg" data-overlay="0.5" id="testimonials">
        <div className="container">
            <Spacer my="50px" />
            <div className="row">
                <div className="col-md-12">
                    <div className="review-slides owl-carousel">
                        <div className="review">
                            <div className="review-icons"><img src="assets/images/avatar/sbi.png" alt="" />
                            </div>
                            <div className="review-content">
                                <blockquote>
                                    <p>Incompli has been a trusted partner for over 6 years now. They provide a comprehensive suite of stormwater services for the entire project life-cycle. From swppp development all the way through project closeout, they handle everything so my team can focus on building.</p>
                                    <footer className="blockquote-footer">
                                        <div>Paul Nuytten</div>
                                        <div>SBI Builders Inc.</div>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                        <div className="review">
                            <div className="review-icons"><img src="assets/images/avatar/dpr.jpg" alt="" />
                            </div>
                            <div className="review-content">
                                <blockquote>
                                    <p>Incompli has always been our go-to when it comes to environmental site management. The Incompli team is quick to respond and thorough with their documentation. The automated system they has have is user friendly and the onsite inspectors engage the team to make sure we are in alignment <br /> with our SWPPP goals and responsibilities.</p>
                                    <footer className="blockquote-footer">
                                        <div>Joe Bilios</div>
                                        <div>DPR Construction</div>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                        <div className="review">
                            <div className="review-icons"><img src="assets/images/avatar/swenson.png" alt="" />
                            </div>
                            <div className="review-content">
                                <blockquote>
                                    <p>Incompli has the expert knowledge, timely response protocol, and excellent customer service to address all contractor stormwater compliance needs.</p>
                                    <footer className="blockquote-footer">
                                        <div>James Brown</div>
                                        <div>Barry Swenson Builder</div>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                        <div className="review">
                            <div className="review-icons"><img src="assets/images/avatar/balfour_beatty.jpg" alt="" />
                            </div>
                            <div className="review-content">
                                <blockquote>
                                    <p>I have had the pleasure to partner with Incompli, Inc. for two projects in a row, this is not always the case with Environmental Services as they seem to come and go over time. Incompli has been a stead-fast, timely, professional service at a competitive price. They are a true partner to the State of California, Owners, Clients, Contractors, and the Environment. From their Professional Inspectors attention to detail to the knowledgeable and helpful office staff, the experience level and leadership of Incompli shines through every time.</p>
                                    <footer className="blockquote-footer">
                                        <div>Chris Giffin</div>
                                        <div>Balfour Beatty Construction</div>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Spacer my="50px" />
        </div>
    </section>
);

export default Testimonials;
