import React, { useState } from "react";
import PropTypes from "prop-types";

const IconBoxFixed = ({ icon, title, children }) => {
    return (
        <div className="col-md-6">
            <div className="icon-box">
                <div className="icon-box-icon">
                    <span className={icon}></span>
                </div>
                <div className="icon-box-title">
                    <h6>{title}</h6>
                </div>
                <div>
                    <p>{children}</p>
                </div>
            </div>
        </div>
    );
}

IconBoxFixed.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired
}

export default IconBoxFixed;
