import React from "react";
import PropTypes from "prop-types";

const Banner = ({ image, overlay, children, className, ...props }) => (
    <section className={"module-cover parallax " + (className ? className : "")} data-background={image} data-overlay={overlay} {...props}>
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-9 m-auto text-center">
                    {children}
                </div>
            </div>
        </div>
    </section>
);

Banner.propTypes = {
    image: PropTypes.string,
    overlay: PropTypes.string
};

Banner.defaultProps = {
    image: "assets/images/module-1.jpg",
    overlay: "0.5"
};

export default Banner;