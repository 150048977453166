import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import IconBox from "../components/IconBox";
import IconBoxFixed from "../components/IconBoxFixed";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

const jsonData = {
    "@context":"https://schema.org",
    "@type":"FAQPage",
    "mainEntity": { "@type":"Question","name":"What does SWPPP stand for?","acceptedAnswer": {"@type":"Answer","text":"Storm Water Pollution Prevention Plan"}},
    "@type":"Question","name":"What is the purpose of a SWPPP?","acceptedAnswer": {"@type":"Answer","text":"The SWPPP is a document unique to your project and company that highlights all of the construction/industrial operations conducted on your site/facility and identifies pollution control practices that will minimize pollutants from reaching stormwater runoff. A SWPPP is required under California’s Construction General Permit (CGP) and Industrial General Permit (IGP)."},
    "@type":"Question","name":"Does my site need a SWPPP?","acceptedAnswer": {"@type":"Answer","text":"National Pollutant Discharge Elimination System (NPDES) permits are often a requirement for facilities that have the potential to discharge pollutants into water bodies. In addition, construction activities that disturb an acre or more of land will generally need an NPDES permit for stormwater discharges, while Industrial permits depend on the Standard Industrial Classification (SIC) code. "},
    "@type":"Question","name":"Who creates a SWPPP?","acceptedAnswer": {"@type":"Answer","text":"Construction SWPPPs are created by a Qualified SWPPP Developer (QSD) and Industrial SWPPPs are developed by a Qualified Industrial Storm Water Practitioner (QISP). Our QSDs/QISPs will work with you to develop a SWPPP that is site-specific to your project. "},
    "@type":"Question","name":"What is the application process like?","acceptedAnswer": {"@type":"Answer","text":"The SWPPP and Facility Site Map must be submitted with your Notice of Intent (NOI) application to comply with the California General Permit. Once our QSDs have developed your SWPPP, they will complete the NOI, which is submitted upon the Legally Responsible Person’s ( LRP’s) certification in the state-run Storm Water Multiple Applications and Tracking System (SMARTS). The submitted SWPPP will include maps, general permits, and Best Management Practices (BMPs) that are unique to your site and project. The updated General Permit has included a number of new requirements for the SWPPP, including increased sampling and minimum best management practices. "},
    "@type":"Question","name":"When should I implement the SWPPP?","acceptedAnswer": {"@type":"Answer","text":"The SWPPP must be implemented immediately upon any sort of site mobilization to reduce pollution and minimize the chances of being fined."},
    "@type":"Question","name":"How often should I revise the SWPPP?","acceptedAnswer": {"@type":"Answer","text":"SWPPPs are in need of revision any time there is a change to the site/facility. For example, a change of the LRP, an extension on how long the project will take, any changes to the size of the site, and so on. "},
    "@type":"Question","name":"How is the SWPPP submitted?","acceptedAnswer": {"@type":"Answer","text":"The SWPPP must be submitted via SMARTS. Incompli’s certified QSDs and QISPs are experts at navigating what can be a confusing submission and revision process. "},
    "@type":"Question","name":"Who submits the SWPPP?","acceptedAnswer": {"@type":"Answer","text":"While Incompli is able to submit the SWPPP on your company’s behalf, all projects must assign a Legally Responsible Person (LRP) to officially certify all reports including, but not limited to: NOIs NECs SWPPPS ARs NOTs Level 1 &amp; 2 ERA Reports"},
    "@type":"Question","name":"What are the SWPPP requirements?","acceptedAnswer": {"@type":"Answer","text":"California SWPPPs are required to include a multitude of information, from Site BMPs (which are effective and result in the reduction or elimination of pollutants in storm water discharges) to calculations and design details for BMP controls. Our team of experts ensure all requirements are included and submitted with your SWPPP."},
    "@type":"Question","name":"What should be included in the Facility Site Map?","acceptedAnswer": {"@type":"Answer","text":"A Facility Site Map depicting areas of all activity, stormwater drainage areas, and sampling locations is required by the General Permit. We specialize in drafting Facility Site Maps that meet all General Permit requirements. Construction Facility Site Maps must include: The Project’s Surrounding Area (vicinity) Flow DirectionConstruction Site Boundaries Storm Drain Inlets that Receive Runoff from the Project Discharge Locations Sampling Locations (if applicable) Locations of Erosion Control BMPs Locations of Sediment Control BMPs Locations of Sensitive Habitats, Watercourses, or Other Features that are Not to be Disturbed Waste Storage Areas Vehicle Storage Areas Material Storage Areas Entrance and Exits Fueling Locations Laydown YardIndustrial Facility Site Maps must include: Legend and Notes that are Legible Facility Boundaries Drainage Areas Direction of FlowWater Bodies on the Property Areas of Soil Erosion."}
}

const SWPPPFAQ = () => (
    <Layout>
        <MetaTags>
            <title>SWPPP requirements California - FAQ</title>
            <meta name="description" content="Have questions about SWPPP? Click to view our FAQ on everything you need to know for SWPPP and its requirements." />
        </MetaTags>
        <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonData) }}
      />

        <Hero background="/assets/images/services-hero-bg.jpg">
            <h1 className="text-white">California SWPPP FAQ</h1>
            <p className="text-white" style={{ maxWidth: `760px`, margin: `auto` }}></p>
        </Hero>
         <section id="module" className="module divider-bottom">
         <div className="container">
            <div className="row">
                <div className="col-md-6 m-auto text-center">
                    <h2>Frequently Asked Questions:</h2>
                </div>
            </div>
            <Spacer my="20px" />
                <div className="row">
                    <IconBox title="What does SWPPP stand for?">
                        Storm Water Pollution Prevention Plan                    
                    </IconBox>
                    <IconBox title="What is the purpose of a SWPPP?">
                        The SWPPP is a document unique to your project and company that highlights all of the construction/industrial operations conducted on your site/facility and identifies pollution control practices that will minimize pollutants from reaching stormwater runoff. A SWPPP is required under the Construction General Permit (CGP) for both California and Texas.
                    </IconBox>
                    <IconBox title="Does my site need a SWPPP?">
                        National Pollutant Discharge Elimination System (NPDES) or Texas Pollutant Discharge Elimination System (TPDES) permits are often a requirement for facilities that have the potential to discharge pollutants into water bodies. In addition, construction activities that disturb an acre or more of land will generally need an NPDES or TPDES permit for stormwater discharges, while Industrial permits depend on the Standard Industrial Classification (SIC) code.
                    </IconBox>
                    <IconBox title="Who creates a SWPPP?">
                        Construction SWPPPs are created by a Qualified SWPPP Developer (QSD) and Industrial SWPPPs are developed by a Qualified Industrial Storm Water Practitioner (QISP). Our QSDs/QISPs will work with you to develop a SWPPP that is site-specific to your project. 
                    </IconBox>
                    <IconBox title="What is the application process like?">
                        The SWPPP and Facility Site Map must be submitted with your Notice of Intent (NOI) application to comply with the California or Texas General Permit. Once our QSDs have developed your SWPPP, they will complete the NOI, which is submitted upon the Legally Responsible Person’s ( LRP’s) certification in the state-run Storm Water Multiple Applications and Tracking System (SMARTS) for California or State of Texas Environmental Electronic Reporting System (STEERS) for Texas. The submitted SWPPP will include maps, general permits, and Best Management Practices (BMPs) that are unique to your site and project.
                    </IconBox>
                    <IconBox title="When should I implement the SWPPP?">
                        The SWPPP must be implemented immediately upon any sort of site mobilization to reduce pollution and minimize the chances of being fined.
                    </IconBox>
                    <IconBox title="How often should I revise the SWPPP?">
                        SWPPPs are in need of revision any time there is a change to the site/facility. For example, a change of the LRP, an extension on how long the project will take, any changes to the size of the site, and so on. 
                    </IconBox>
                    <IconBox title="How is the SWPPP submitted?">
                        The SWPPP must be submitted via SMARTS/STEERS, depending on the state. Incompli’s certified QSDs and QISPs are experts at navigating what can be a confusing submission and revision process.
                    </IconBox>
                    <IconBox title="Who submits the SWPPP?">
                        While Incompli is able to submit the SWPPP on your company’s behalf, all projects must assign a Legally Responsible Person (LRP) to officially certify all reports including, but not limited to:  
                    <div>
                        <ul className="arrow-list">
                            <li>NOIs</li>
                            <li>NECs</li>
                            <li>SWPPPS</li>
                            <li>ARs</li>
                            <li>NOTs </li>
                            <li>Level 1 & 2 ERA Reports</li>
                        </ul>
                    </div>
                    </IconBox>
                    <IconBox title="What are the SWPPP requirements?">
                        California and Texas SWPPPs are required to include a multitude of information, from site BMPs (which are effective and result in the reduction or elimination of pollutants in storm water discharges) to calculations and design details for BMP controls. Our team of experts ensure all requirements are included and submitted with your SWPPP.
                    </IconBox>
                    <IconBox title="What should be included in the Facility Site Map?">
                        A Facility Site Map depicting areas of all activity, stormwater drainage areas, and sampling locations is required by the General Permit. We specialize in drafting Facility Site Maps that meet all General Permit requirements.
                        <br/><p>Construction Facility Site Maps must include:</p>
                        <div>
                            <ul className="arrow-list">
                                <li>The Project’s Surrounding Area (vicinity)</li>
                                <li>Flow Direction</li>
                                <li>Construction Site Boundaries</li>
                                <li>Storm Drain Inlets that Receive Runoff from the Project</li>
                                <li>Discharge Locations</li>
                                <li>Sampling Locations (if applicable)</li>
                                <li>Locations of Erosion Control BMPs</li>
                                <li>Locations of Sediment Control BMPs</li>
                                <li>Locations of Sensitive Habitats, Watercourses, or Other Features that are Not to be Disturbed</li>
                                <li>Waste Storage Areas</li>
                                <li>Vehicle Storage Areas</li>
                                <li>Material Storage Areas</li>
                                <li>Entrance and Exits</li>
                                <li>Fueling Locations</li>
                                <li>Laydown Yard</li>
                            </ul>
                        </div>
                        <br/><p>Industrial Facility Site Maps must include:</p>
                        <div>
                            <ul className="arrow-list">
                                <li>Legend and Notes that are Legible</li>
                                <li>Facility Boundaries</li>
                                <li>Drainage Areas</li>
                                <li>Direction of Flow</li>
                                <li>Water Bodies on the Property</li>
                                <li>Areas of Soil Erosion</li>
                            </ul>
                        </div>
                    </IconBox>
                </div>
            </div>
        </section>
    </Layout>
);

export default SWPPPFAQ
