import React from "react";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { baseUrl } from "../config/config.json";
import MetaTags from "react-meta-tags";
import excerpts from "excerpts";

class Post extends React.Component {
    state = {
        isLoaded: false,
        post: null,
        error: null
    };

    componentDidMount() {
        const { id } = this.props.match.params;
        fetch(`${baseUrl}/articles/${id}`)
            .then((res) => res.json())
            .then((post) => this.setState({
                isLoaded: true,
                post: post,
                error: null
            }))
            .catch((error) => this.setState({
                isLoaded: true,
                error: error.message
            }));
    }

    getExcerpt() {
        return excerpts(this.state.post.body, { words: 50, append: '...' });
    }

    render() {
        const { isLoaded, post } = this.state;
        return (
            <Layout>
                <MetaTags>
                    <title>Incompli &rArr; {isLoaded ? post.title : "Environmental Compliance Blog"}</title>
                    <meta name="description" content={isLoaded ? this.getExcerpt() : "Article about Environmental Compliance."} />
                </MetaTags>
                <section className="module parallax text-center" data-background="/assets/images/blog-hero-bg.jpg" data-overlay="0.4">
                    <div className="container">
                        <div className="row">
                            {isLoaded && (
                                <div className="col-md-12">
                                    <h2>{post.title}</h2>
                                    <ol className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="/blog">Blog</Link></li>
                                        <li className="breadcrumb-item active">{post.title}</li>
                                    </ol>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                {isLoaded && (
                    <section className="module">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 m-auto">
                                    <div className="post-preview">
                                        <img src={`${baseUrl}${post.image.formats.medium.url}`} alt=""/>
                                    </div>
                                    <div className="post-header">
                                        <h1 className="post-title">{post.title}</h1>
                                        <ul className="post-meta">
                                            <li>{(new Date(post.created_at)).toLocaleDateString('en-US')}</li>
                                            <li>{post.created_by.firstname} {post.created_by.lastname}</li>
                                        </ul>
                                    </div>
                                    <div className="post-content" dangerouslySetInnerHTML={{ __html: post.body }}/>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </Layout>
        );
    }
}

export default Post;