import React from "react";
import Layout from "../components/Layout";
import MainHero from "../components/MainHero";
import About from "../components/About";
import Testimonials from "../components/Testimonials";
import Posts from "../components/Posts";
import WhoWeServe from "../components/WhoWeServe";
import MetaTags from "react-meta-tags";

const Home = () => (
    <Layout>
        <MetaTags>
            <title>Stormwater Compliance Consulting & SWPPP Services in California</title> 
            <meta name="description" content="Since 2010, our team of California stormwater compliance consulants have been helping companies adhere to SWPPP inspections & regulations. Learn more!"/>
            <link rel="canonical" href="https://www.incompli.com/" />
    </MetaTags>
        <MainHero image="/assets/images/hero-bg.jpg" />
        <About />
        <WhoWeServe />
        <Testimonials />
        {/* <Posts /> */}
    </Layout>
);

export default Home;
