import React from "react";
import excerpts from "excerpts";
import { Link } from "react-router-dom";
import { baseUrl } from "../../config/config.json";

const PostPreview = ({ post }) => (
    <article className="post">
        <div className="post-preview"><Link to={`/blog/${post.id}`}><img src={`${baseUrl}${post.image.formats.medium.url}`} alt="" /></Link></div>
        <div className="post-wrapper">
            <div className="post-header">
                <h2 className="post-title"><Link to={`/blog/${post.id}`}>{post.title}</Link></h2>
                <ul className="post-meta">
                    <li>{(new Date(post.created_at)).toLocaleDateString('en-US')}</li>
                    <li>{post.created_by.firstname} {post.created_by.lastname}</li>
                </ul>
            </div>
            <div className="post-content">
                <p>{excerpts(post.body, { words: 50, append: '...' })}</p>
            </div>
            <div className="post-more"><Link to={`/blog/${post.id}`}>Read more</Link></div>
        </div>
    </article>
);

export default PostPreview;